import { graphql, Link, useStaticQuery } from "gatsby";
import * as React from "react"
import Layout from "../components/Layout";
import MetaImage from "../components/MetaImage";
import './../styles/global.css';

const CategoryLabel = ({ category, colors }) => {
    let color = colors.find(c => c[0].toLowerCase() === category.toLowerCase());
    if (color) color = color[1];

    return <span className={`text-2xs font-semibold bg-green-500 rounded-sm inline-block p-1 uppercase text-white`}
        style={({ background: color })}>{category}</span>
}

const Pagination = ({ numPages, currentPage }) => {
    const isFirst = currentPage === 1;
    const isLast = currentPage === numPages

    const pages = [];

    let numPagesBefore = 2;
    let numPagesAfter = 2;

    for (let i = 0; i < numPagesBefore; i++) {
        if (currentPage > i + 1) {
            pages.unshift(currentPage - (i + 1));
        } else {
            numPagesAfter++;
        }
    }

    pages.push(currentPage);

    for (let i = 0; i < numPagesAfter; i++) {
        if (currentPage < numPages - i) {
            pages.push(currentPage + i + 1);
        }
    }

    if (pages[pages.length - 1] === pages[pages.length - 2] + 1 && pages[pages.length - 1] < numPages) {
        pages[pages.length - 1] = null
        pages.push(numPages);
    }

    if (pages[0] === pages[1] - 1 && pages[0] > 1) {
        pages[0] = 1;
        pages.splice(1, 0, null)
    }

    return (
        <div className="bg-white flex items-center rounded-sm px-3" style={({ boxShadow: `0px 4px 7px rgba(206, 206, 206, 0.13` })}>
            <Link to={!isFirst ? `/${currentPage === 2 ? '' : currentPage - 1}` : undefined} className={`inline-block mr-3 ${isFirst ? 'text-gray-200' : 'text-black'}`}>
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" className="stroke-current">
                    <path d="M2.83334 8.18294L12.8333 8.18294" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M6.86654 12.1992L2.83321 8.18317L6.86654 4.1665" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
            </Link>
            <ul className="flex items-center">
                {pages.map((i) => {
                    return (i === null
                        ? <li key={i} className="align-bottom p-2">...</li>
                        : <li key={i}>
                            <Link to={i === 1 ? `/` : `/${i}`} className={`inline-block p-2 text-sm ${currentPage !== (i) ? 'text-gray-200' : ''}`}>{i}</Link>
                        </li>
                    )

                })}
            </ul>
            <Link to={!isLast ? `/${currentPage + 1}` : undefined} className={`inline-block ml-3`}>
                <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" className={` ${isLast ? 'text-gray-200' : 'text-black'}`}>
                    <path d="M13.1667 7.81706L3.16666 7.81706" strokeWidth="1.5" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M9.13346 3.80083L13.1668 7.81683L9.13346 11.8335" stroke="currentColor" fill="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
            </Link>
        </div >
    );
}

const SmallThumbnail = ({ node, fallback }) => {

    const url = node.thumbnail

    if (node.thumbnail && node.thumbnail.childImageSharp && node.thumbnail.childImageSharp.fluid) {
        return <img src={node.thumbnail.childImageSharp.fluid.src} className="flex-shrink-0 w-full h-full" />
    } else {
        return <img src={fallback} className="flex-shrink-0 w-full h-full" />
    }

}

// markup
const ArticleList = ({ data, pageContext, location }) => {
    const edges = data.allArticle.edges;
    const categoryColors = data.allSite.edges[0].node.siteMetadata.categoryColors;

    const defaultThumbnailSmall = data.defaultThumbnailSmall.childImageSharp.fixed.src;
    const defaultThumbnailLarge = data.defaultThumbnailLarge.childImageSharp.fixed.src;


    const firstNode = edges[0].node;
    const nodes = edges.slice(1).map(e => e.node);
    console.log('firstNOde', firstNode);

    const firstNodeThumbnailUrl = firstNode.thumbnail && firstNode.thumbnail.childImageSharp && firstNode.thumbnail.childImageSharp.fluid
        ? firstNode.thumbnail.childImageSharp.fluid.src
        : defaultThumbnailLarge;
    return (
        <Layout>
            {firstNode.thumbnail && <MetaImage image={firstNode.thumbnail.metaImage} location={location} />}

            <div className="relative bg-cover bg-no-repeat mb-14" style={({ backgroundImage: `url(${firstNodeThumbnailUrl})`, paddingBottom: '51%' })}>
                <div aria-hidden={true} className="absolute w-full h-full" style={({ background: `linear-gradient(180deg, rgba(0, 0, 0, 0) 35.42%, rgba(0, 0, 0, 0.83) 100%)` })}></div>

                <div className="absolute h-full py-6 px-4 flex flex-col justify-end">
                    <Link className="inline-block" to={firstNode.slug}>
                        <div className="mb-2">
                            <div>{firstNode.categories.map(c => <CategoryLabel key={c} colors={categoryColors} category={c} />)}</div>
                        </div>

                        <h1 className="text-white text-2xl mb-2">{firstNode.title}</h1>
                    </Link>
                </div>

            </div>


            {nodes.map(node => {
                return (
                    <div key={node.title} className=" mb-8">
                        <Link className="flex" to={`${node.slug}`}>
                            <div className="mr-4 w-1/4 flex-shrink-0">
                                <SmallThumbnail node={node} fallback={defaultThumbnailSmall} />
                            </div>
                            <div>
                                <div className="mb-2">
                                    {node.categories.map(c => <CategoryLabel key={c} colors={categoryColors} category={c} />)}
                                </div>
                                <h1 className="text-2xl mb-4">{node.title}</h1>

                                {node.excerpt && <div className="text-gray-200 text-sm">
                                    {node.excerpt}
                                </div>}
                            </div>

                        </Link>
                    </div>
                )
            })}

            {pageContext.numPages > 1 && <div className="my-8 flex justify-center md:my-20" >
                <Pagination numPages={pageContext.numPages} currentPage={pageContext.currentPage} />
            </div>}

        </Layout>
    )
}
export default ArticleList;


export const articleListQuery =
    graphql`
  query ($skip: Int!, $limit: Int!, $filter: ArticleFilterInput)  {
    allArticle(
        sort: {fields: date, order: DESC}
        limit: $limit
        skip: $skip
        filter: $filter
    ) {
      edges {
        node {
          title
          categories
          excerpt
          slug
          thumbnail {
            childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                  src
                }
              }
            metaImage: childImageSharp {
                fixed(width: 1200, height: 1200) {
                    ...GatsbyImageSharpFixed
                    src
                }
            }
          }
        }
      }
    }
    allSite {
        edges {
          node {
            siteMetadata {
              categoryColors
            }
          }
        }
    }
    defaultThumbnailSmall: file (
        name: { eq: "default-thumbnail" }
    ) {
        childImageSharp {
            fixed(width: 180, height: 150) {
                ...GatsbyImageSharpFixed
                src
            }
        }
    }
    defaultThumbnailLarge: file (
        name: {eq: "default-thumbnail" }
    ) {
        childImageSharp {
            fixed(width:720, height: 370) {
                ...GatsbyImageSharpFixed
                src
            }
        }
    }
  }
`;